import React, { useContext, useState } from "react";
import "./style.scss";
import { Dropdown, List } from "semantic-ui-react";
import { KEY_LANGUAGE_MATCHER } from "../../../constants";
import LangContext from "../../../context/LangContext";
import routes from "../../../routes";

const MobileMenu = () => {
  const [menu, toggleMenu] = useState(false);
  const [flag, switchFlag] = useState(true);
  const { switchLang } = useContext(LangContext);
  const currentLanguage = flag ? "en-US" : "ru-RU";

  const hamburgerClass = menu ? "open" : "";
  const menuClass = menu ? "mobile-open-menu" : "mobile-close-menu";

  return (
    <div className="mobile-nav-bar mobile-view">
      <div
        className="menu-icon-wrapper float-left"
        onClick={() => toggleMenu(!menu)}
      >
        <div id="hamburger" className={hamburgerClass}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className={menuClass}>
        {routes.map((item, key) => {
          return (
            <List.Item
              name={"error"}
              key={key}
              as="a"
              href={item.path}
              className="mobile-sidebar-menu"
            >
              <List.Icon name={item.icon} />
              <List.Content>{item.name[flag ? 0 : 1]}</List.Content>
            </List.Item>
          );
        })}

        <List.Item>
          <Dropdown
            floating
            compact
            selection
            value={KEY_LANGUAGE_MATCHER[currentLanguage]}
            options={[
              { key: "en-US", text: "EN", value: "EN" },
              { key: "ru-RU", text: "RU", value: "RU" },
            ]}
            onChange={() => {
              switchFlag(!flag);
              switchLang(currentLanguage);
            }}
          />
        </List.Item>
      </div>
    </div>
  );
};

export default MobileMenu;
