import React, { useContext, useState } from "react";
import "./style.scss";
import logo from "../../../assets/image2.svg";
import { Menu, Container, Image, Sticky, Dropdown } from "semantic-ui-react";
import { KEY_LANGUAGE_MATCHER } from "../../../constants";
import LangContext from "../../../context/LangContext";
import MobileMenu from "./MobileNavBar";
import routes from "../../../routes";

// const LANGUAGE_OPTIONS = {
//   "ru-RU": [
//     { key: "en-US", text: "Английский", value: "Английский" },
//     { key: "ru-RU", text: "Русский", value: "Русский" },
//   ],
//   "en-US": [
//     { key: "en-US", text: "English", value: "English" },
//     { key: "ru-RU", text: "Russian", value: "Russian" },
//   ],
// };
const NavBar = () => {
  const [flag, switchFlag] = useState(true);
  const { switchLang } = useContext(LangContext);

  const currentLanguage = flag ? "ru-RU" : "en-US";

  return (
    <div>
      <Sticky className="nav-bar-sticky">
        <Container className="mobile-nav-bar-container" fluid>
          <Menu className="nav-bar-menu" borderless>
            <MobileMenu />
            <Menu.Menu className="logo-menu-item">
              <Menu.Item as={"a"} href="/#home" className="logo-container">
                <Image src={logo} />
                <span className="logo-name">KRJ</span>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position={"right"}>
              {routes.map((item, key) => (
                <Menu.Item
                  key={key}
                  as="a"
                  href={item.path}
                  className="nav-bar-menu-items"
                >
                  {item.name[flag ? 0 : 1]}
                </Menu.Item>
              ))}
              <Menu.Item>
                <Dropdown
                  floating
                  compact
                  className="nav-bar-menu-language"
                  placeholder="Compact"
                  selection
                  value={KEY_LANGUAGE_MATCHER[currentLanguage]}
                  options={[
                    { key: "en-US", text: "EN", value: "EN" },
                    { key: "ru-RU", text: "RU", value: "RU" },
                  ]}
                  onChange={() => {
                    switchFlag(!flag);
                    switchLang(currentLanguage);
                  }}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Container>
      </Sticky>
    </div>
  );
};

export default NavBar;
