import React from "react";
import { Image as CloudImage } from "cloudinary-react";
import { Image } from "semantic-ui-react";
import banner from "../../../assets/banner.jpg";
import "./style.scss";

const Header = () => {
  return (
    <div id="home">
      {/* <CloudImage
          cloudName={"koruja"}
          className="banner"
          publicId="koruja/assets/bannerr.jpg"
        ></CloudImage> */}
      <Image src={banner} className="banner" />
      <a
        href={"https://www.instagram.com/krj.export/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <CloudImage
          cloudName={"koruja"}
          className={"icon-instagram-default icon-banner-instagram"}
          publicId="koruja/assets/instagram0.2_v2ozw6"
        ></CloudImage>
      </a>
    </div>
  );
};
export default Header;
